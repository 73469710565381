import { post } from '@/utils/request'

//获取出库目录
export function getList(data) {
    return post('/admin/storage/storageGoodsList', data)
}
//员工列表
export function getUserlist(data) {
    return post('/admin/storage/getUserList', data)
}
// 获取店铺列表
export function getStoreList(data) {
    return post('admin/merchant/listMerchant', data)
}
//获取仓库商品详情
export function getDetail(data) {
    return post('/admin/storage/storageGoodsInfo', data)
}
// 获取最新出货单号
export function getOutbound(data) {
    return post('/admin/inventory/getOutbound', data)
}
// 获取店铺分类
export function getShopClass(data) {
    return post('/admin/inventory/getShopClass', data)
}
// 获取当天时间
export function getToday(data) {
    return post('admin/storage/getToday', data)
}
// 申请出库
export function applyOutbound(data) {
    return post('admin/inventory/applyOutbound', data)
}
//修改
export function getEdit(data) {
    return post('psi/storage/editStorage', data)
}
//添加
export function getAdd(data) {
    return post('psi/storage/addStorage', data)
}
//删除
export function getDel(data) {
    return post('psi/storage/delStorage', data)
}

//商品id
export function getOption2(data) {
    return post('psi/commodity/xiala', data)
}
//仓库
export function getOption(data) {
    return post('psi/warehouse/Warehouse', data)
}
//详情
export function getInfo(data) {
    return post('psi/storage/infoStorage', data)
}
//搜索记录
export function getSearch(data) {
    return post('psi/search/list', data)
}
//详情
export function getSearchDel(data) {
    return post('psi/search/del', data)
}







